.qrviewer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;

    .subBody {
        width: 450px;
        height: 650px;
        background-color: white;
        border-top: 10px solid #3277D7;
        border-bottom: 10px solid #F1C451;
        align-items: center;
    }

    h2 {
        text-align: center;
        font-family: 'Poppins';
        display: block;
        font-size: 1.5em;
        font-weight: bold;
    }

    .textHeading {
        margin-top: 25px;
    }

    p {
        text-align: center;
        margin-top: -15px;
    }

    .logo {
        width: 250px;
        height: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }

    .qr-logo {
        width: 300px;
        height: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
}