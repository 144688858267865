.MarketPlaceProfile {
    background-color: #F5F8FA;
    padding: 30px 60px 0px 30px;
    min-width: 100vw;
    min-height: 86.5vh;
    margin: -25px -12px 0 -12px;

    &-container {
        min-width: 1366px;
        max-width: 1366px;
        margin: 0 auto;

        @media only screen and (max-width: 1400px) {
            min-width: 1224px;
            max-width: 1224px;
        }

        @media only screen and (max-width: 1270px) {
            min-width: 1000px;
            max-width: 1000px;
        }

        @media only screen and (max-width: 1060px) {
            min-width: 920px;
            max-width: 920px;
        }

        @media only screen and (max-width: 991px) {
            min-width: 100%;
            max-width: 100%;
        }
    }


    &_top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .back {
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: #fff;
            margin-right: 15px;
            box-shadow: 1px 4px 20px #f3f3f3;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            cursor: pointer;
        }

        .row {
            width: 100%;
        }

    }

    &_card {
        background-color: $white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 1px 4px 20px #F3F3F3;
        width: 100%;
        margin-bottom: 50px;

        // &.left {
        //     position: static;
        //     // position: -webkit-sticky;
        //     top: 250px;
        // }

        .profile {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 15px;

            @media only screen and (max-width: 550px) {
                flex-direction: column;
            } 


            &_img {
                width: 115px;
                height: 115px;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
                margin-right: 20px;
                filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.03));
                -webkit-filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.03));
                object-fit: cover;

                @media only screen and (max-width: 1270px) {
                    width: 100px;
                }

                @media only screen and (max-width: 990px) {
                    width: 115px;
                }

                @media only screen and (max-width: 550px) {
                    width: 100%;
                    height: 300px;
                    margin-right: 0px;
                    margin-bottom: 20px;
                } 
            }

            &_details {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                @media only screen and (max-width: 550px) {
                    width: 100%;
                } 

                &-name {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    color: #3F4254;
                }

                &-position {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 21px;
                    color: #A1A5B7;
                    margin-bottom: 12px;
                }

                &-row {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                &-card {
                    padding: 5px 10px;
                    background: #FFFFFF;
                    border: 1px dashed #DDDDDD;
                    border-radius: 10px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    margin-right: 5px;

                    &:nth-child(even) {
                        margin-left: 5px;
                        margin-right: 0px;
                    }
                    

                    .sft {
                        &_img {
                            width: 22px;
                            height: 22px;
                            margin-right: 10px;
                            object-fit: contain;

                            @media only screen and (max-width: 1270px) {
                                width: 19px;
                                height: 19px;
                            }
                        }

                        &-number {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            color: #3F4254;
                            height: 22px;
                            margin-top: -3px;

                            @media only screen and (max-width: 1270px) {
                                font-size: 15px;
                            }
                        }

                        &-tag {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 21px;
                            color: #B5B5C3;

                            @media only screen and (max-width: 1270px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            &-cus {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #009EF7;
                padding: 10px;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                
            }               
        }

        .PersonalInformation {
            margin-top: 20px;
            
            &_header {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 50px;
                color: #3F4254;
                border-bottom: 1px solid #E4E6EF;
            }
        }

        .information {
            width: 100%;
            // background-color: #000;
            padding-top: 15px;

            &__mainheader {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 50px;
                color: #3F4254;
                border-bottom: 1px solid #E4E6EF;
            }

            &-header {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #3F4254;
            }

            &-info {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #7E8299;
            }

        }
    }
}


.MarketPlaceProfileTabs {
    background-color: #009EF700;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;

    &_tab {
        padding: 0px 0px 10px;
        margin-right: 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #A1A5B7;
        border-bottom: 2px solid #009EF700;
        word-break: keep-all;
        white-space: nowrap;

        &.active {
            color: #009EF7;
            border-bottom: 2px solid #009EF7;
        }
    }

    &_body {

        .information {
            width: 100%;
            // background-color: #000;
            padding-top: 15px;

            &_mainheader {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #3F4254;
                border-bottom: 1px solid #E4E6EF;
                padding-bottom: 6px;
                margin-bottom: 15px;
            }

            &-header {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #3F4254;
            }

            &-info {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #7E8299;
            }

        }
    }

    .rs-nav-item {
        padding: 0px 0px 10px;
        margin-right: 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #A1A5B7;
    }
    
    .rs-nav-item-active {
        border-bottom: 1px solid #009EF7;
    }

}