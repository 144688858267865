//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: $display-block;
  }
  .auth-logo-light {
    display: $display-none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// authentication full page

.auth-full-bg {
  background-color: rgba($primary, 0.25);
  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }

  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background: url("../../../images/bg-auth-overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.auth-full-page-content {
  display: flex;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

// auth page re designs
.authPage {
  background: url("../../../images/cus/bg2.jpg");
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.register {
  }

  &_card {
    min-width: 70vw;
    max-width: 70vw;
    min-height: 70vh;
    max-height: 70vh;
    // background-color: #0ff;
    box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      min-width: 95vw;
      max-width: 95vw;
    }

    &.register {
      min-width: 100vw;
      max-width: 100vw;
      min-height: 100vh;
      max-height: 100vh;

      @media only screen and (max-width: 769px) {
        min-width: 99vw;
        max-width: 99vw;
        min-height: 99vh;
        max-height: 99vh;
      }

      @media only screen and (max-width: 600px) {
        min-width: 90vw;
        max-width: 90vw;
        min-height: 95vh;
        max-height: 95vh;
      }
    }

    &-left {
      display: flex;
      flex: 1.2;
      justify-content: center;
      align-items: center;
      min-height: 70vh;
      max-height: 70vh;
      // background-color: #fff;
      // background: linear-gradient(
      //   149deg,
      //   rgba(213, 231, 255, 1) 0%,
      //   rgba(225, 185, 255, 1) 53%,
      //   rgba(255, 230, 163, 1) 100%
      // );
      background-image: url("../../../images/cus/cheerful-young-clinic-staff-looking-at-camera-1.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 25px;

      @media only screen and (max-width: 769px) {
        display: none;
      }

      &.register {
        min-height: 85vh;
        max-height: 85vh;
        background-image: url("");

        .content {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          max-width: 70%;

          &_img {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 30px;
          }

          h2 {
            font-size: 40px;
            line-height: 140%;
            color: #444;
            // text-align: center;
            @media only screen and (max-width: 1400px) {
              font-size: 30px;
            }

            span {
              font-size: 35px;
            }

            em {
              // background: -webkit-linear-gradient(
              //   149deg,
              //   #2d78d9 0%,
              //   #8e3ec9 53%,
              //   #f2c64f 100%
              // );
              // -webkit-background-clip: text;
              // -webkit-text-fill-color: transparent;
              color: #444;
            }
          }

          h4 {
              color: #444;
              font-size: 20px;
              // text-align: center;
            @media only screen and (max-width: 1400px) {
              font-size: 16px;
            }
          }

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            margin-top: 40px;

            li {
              font-size: 20px;
              margin-bottom: 10px;
              line-height: 30px;

              i {
                color: #8db600;
              }
            }
          }
        }
      }

      &.forgot-pass {
        background-image: url("../../../images/cus/young-nurse-at-hospital-3.jpg");
      }

      &-logo {
        width: 350px;
      }
    }

    &-right {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      min-height: 70vh;
      max-height: 70vh;
      background-color: #fff;
      padding: 30px;
      overflow-y: auto;

      @media only screen and (max-width: 600px) {
        padding: 15px;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #2d78d9;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      &.register {
        margin: 50px;
        max-width: 600px;
        min-height: 90vh;
        max-height: 95vh;
        padding: 40px;
        flex: 1;
        border-radius: 10px;
        box-shadow: 6px 6px 1px 0px #2e78d9;

        @media only screen and (max-width: 1400px) {
          max-width: 500px;
          min-height: 90vh;
          max-height: 95vh;
          padding: 15px;
        }

        @media only screen and (max-width: 769px) {
          margin: 0px;
          padding: 40px;
          flex: 1;
          border-radius: 10px;
          box-shadow: 6px 6px 1px 0px #2e78d9;
        }

        @media only screen and (max-width: 600px) {
          padding: 15px;
        }
      }

      &-logo {
        width: 100%;
        height: 80px;
        object-fit: contain;
        margin: 0px auto 25px;

        &.regs {
          margin: 0px auto 25px;
        }
      }

      &-form {
        width: 100%;
        max-width: 70%;

        @media only screen and (max-width: 769px) {
          max-width: 100%;
        }

        h4 {
          width: 100%;
          font-size: 20px;
          color: #666;
          letter-spacing: 0.05em;
        }

        h1 {
          background: -webkit-linear-gradient(
            149deg,
            rgba(213, 231, 255, 1) 0%,
            rgba(225, 185, 255, 1) 53%,
            rgba(255, 230, 163, 1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 25px;
          letter-spacing: 0.05em;
        }

        .form-label {
          font-size: 12px;
          font-weight: bold;
          color: #444;
          letter-spacing: 0.08em;
        }

        .form-control {
          border-radius: 0px;
          min-height: 40px;
          // margin-bottom: 1rem
        }

        .cus-btn {
          border-radius: 0px;
          height: 50px;
          margin-top: 10px;
          font-weight: bold;
          letter-spacing: 0.1em;
          width: 100%;
        }
      }
    }
  }
}


.reg-1-logo {
  margin-top: 110px !important;

  @media only screen and (max-width: 769px) {
    margin-top: 85px !important;
  }

  @media only screen and (max-width: 769px) and (min-height: 800px) {
    margin-top: 0px !important;
  }
}