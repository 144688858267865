.Timecardpage {
  background-color: #f5f8fa;
  min-height: 95vh;

  &-container {
    min-width: 1366px;
    max-width: 1366px;
    margin: 0 auto;

    &.admin {
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
    }

    @media only screen and (max-width: 1400px) {
      min-width: 1224px;
      max-width: 1224px;
    }

    @media only screen and (max-width: 1270px) {
      min-width: 1000px;
      max-width: 1000px;
    }

    @media only screen and (max-width: 1060px) {
      min-width: 920px;
      max-width: 920px;
    }

    @media only screen and (max-width: 991px) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  &-card {
    background-color: $white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 1px 4px 20px #f3f3f3;
    width: 100%;
    margin-bottom: 50px;

    .table-responsive {
      &::-webkit-scrollbar {
        width: 6px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #009ef7;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .table {
      thead {
        tr {
          th {
            &:nth-last-child(1) {
              background-color: #f7f8ff;
              position: sticky;
              position: -webkit-sticky;
              right: 0;
              z-index: 1;
              background-image: linear-gradient(to right, #f8f8f8, white);
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-last-child(1) {
              background-color: #f7f8ff;
              position: sticky;
              position: -webkit-sticky;
              right: 0;
              z-index: 1;
              background-image: linear-gradient(to right, #f8f8f8, white);
            }
          }
        }
      }
    }
  }
}