.custom-onboarding-steps {
  &_container {
    border-radius: 40px;
    background-color: #fff1f1;
    display: flex;
    // flex-direction: row-reverse;
    padding: 7px;
    margin-top: 10px;
  }

  &_circle {
    border-radius: 50%;
    background-color: white;
    height: 50px;
    width: 50px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px #cfc4c4;
  }
  &_content {
    width: calc(100% - 75px);
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    font-size: 12px;
  }
}
