.StagesPage {
  & {
    background-color: #eaebed;
    padding-bottom: 5px;
    padding-top: 75px;
  }
  &-Row {
    width: 140%;
    height: 100%;
    // overflow-y: hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &-Col {
    // float: left;
    // background-color: #f4f5f7;
    background-color: yellow;
    height: calc(100vh - 88px);
    position: relative;
    margin-right: 5px;
    // padding: 0px;
    width: 50%;
  }
  &-Card {
    background-color: white;
    height: fit-content;
    width: 100%;
    align-self: "center";
    padding: 5px;
    margin: 5px;
  }
  &-CardHeader {
    top: 0;
    position: sticky;
  }
  &-CardBody {
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f4f5f7;
    padding: 5px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #009ef7;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      -o-border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
