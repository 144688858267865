.Grid {
  border: 1px solid #d9dddd;
}

.GridItemEven,
.GridItemOdd {
  display: flex;
  align-items: left;
  justify-content: left;
  border-right: 1px solid #d9dddd;
  border-bottom: 1px solid #d9dddd;
}

.GridItemValue {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d9dddd;
  border-bottom: 1px solid #d9dddd;
}

.GridLeftHeader {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f8fb;
  padding: 2px;
  border-right: 1px solid #d9dddd;
  border-bottom: 1px solid #d9dddd;
}

.GridTopHeader {

  background: #f8f8fb;
  display: flex;
  align-items: left;
  justify-content: left;
  border-right: 1px solid #d9dddd;
  border-bottom: 1px solid #d9dddd;
}
.status-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}