.HelpPage {
  & {
    background-color: #fff;
    min-height: 98vh;

    @media only screen and (max-width: 600px) {
      min-height: 101vh;
    }
  }

  &-Navbar {
    width: 100%;
    padding: 19px 123px 19px 77px;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dadada;

    @media only screen and (max-width: 600px) {
      padding: 19px 35px 19px 15px;
    }
  }

  &-NavbarLogo {
    width: 135px;
    height: auto;
  }

  &-NavbarItems {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-NavbarItem1 {
    margin-right: 65px;

    @media only screen and (max-width: 600px) {
      margin-right: 25px;
    }
  }

  &-NavbarItemText1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #89929f;
    margin-bottom: 0px;
  }

  &-NavbarItemText2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
  }

  &-LogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 67px;
  }

  &-Logo {
    width: 338px;
    height: auto;
  }

  &-CardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-Card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 29px 62px 29px 62px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 48px;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
      margin-top: 30px;
      width: 100%;
    }
  }

  &-CardHeader {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #4E4E4E;
  }

  &-CardDescription {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #89929f;
    text-align: center;
  }

  &-ClipboardCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-ClipboardCard {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-left: 48px;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
      margin-top: 30px;
      width: 100%;
    }
  }

  &-ClipboardCardHeader {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    color: #4E4E4E;
  }

  &-ClipboardCardSubtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #89929f;
    margin-bottom: 20px;
  }

  &-ClipboardCardDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #474755;
    margin-top: 10px;
    white-space: pre-wrap;
  }

  &-eventCardContainer {
    display: grid;
    grid-template-rows: repeat(4, minmax(0, 1fr));
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: center;
  }

  &-helpSearchContainer {
    padding-left: 82px;
    padding-top: 10px;

    @media only screen and (max-width: 600px) {
      padding-left: 0px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-helpSearchPanelContainer {
    margin-left: 50px;
    margin-top: -10px;
    width: 50%;

    @media only screen and (max-width: 600px) {
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 90%;
      margin-left: 0px;
    }
  }

  &-helpSearchTabsContainer {
    @media only screen and (max-width: 600px) {
      align-self: flex-start;
      margin-left: 30px;
    }
  }

  &-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #4E4E4E;
    text-align: center;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  &-footerText {
    margin-bottom: 0px;
  }

  &-headerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 38px;

    @media only screen and (max-width: 600px) {
      padding-inline: 20px;
    }
  }

  &-contactUsHeader {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    color: #2F2F36;
  }

  &-subHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-self: flex-start;
      margin-top: 25px;
      margin-bottom: -20px;
      margin-left: 10px;
    }
  }

  &-contactUsDetailHeading {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #2F2F36;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;

    @media only screen and (max-width: 600px) {
      align-self: flex-start;
      margin-bottom: 5px;
    }
  }

  &-contactUsDetailContent {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #89929F;
    margin-left: 3px;
    margin-bottom: 0px;
  }

  &-vl {
    border-left: 0.5px solid #89929F;
    height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &-detailIcon {
    color: '#2F2F36';
    margin-right: 3px;
  }

  &-contactUsCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    @media only screen and (max-width: 600px) {
      padding-inline: 20px;
    }
  }

  &-contactUsCard {
    width: 800px;
    height: 400px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &-contactUsFirstRow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &-contactUsSecondRow {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px 70px !important;

    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 20px 25px !important;
    }
  }

  &-contactInputField {
    border-radius: 20px;
    background-color: rgba(246, 246, 246, 0.5);
    border: 1px solid rgba(137, 146, 159, 0.12)
  }

}