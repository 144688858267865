.customeToolTip {
  display: inline;
  position: relative;

  &:hover {
    .customeToolTip-msg {
      display: block;
      opacity: 1;
      bottom: -15%;
      left: 115%;
      transition: 0.5s all;
    }
  }

  &-child {
    display: inline;
    position: relative;
  }

  &-msg {
    display: none;
    opacity: 0;
    bottom: 0;
    left: 100%;
    position: absolute;
    min-width: fit-content;
    background: #009ef7;
    font-size: 10px;
    color: #fff;
    padding: 5px 7px;
    border-radius: 5px;
    // left: 115%;
    // bottom: -15%;
    max-width: 200%;
    width: 100%;
    z-index: 99;
    white-space: nowrap;
    transition: 0.5s all;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: #009ef7;
      position: absolute;
      transform: rotate(45deg);
      top: 38%;
      left: -3%;
    }
  }
}
