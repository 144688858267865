.Nurses {
  background-color: #f5f8fa;

  &-container {
    min-width: 1366px;
    max-width: 1366px;
    margin: 0 auto;

    &.admin {
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
    }

    @media only screen and (max-width: 1400px) {
      min-width: 1224px;
      max-width: 1224px;
    }

    @media only screen and (max-width: 1270px) {
      min-width: 1000px;
      max-width: 1000px;
    }

    @media only screen and (max-width: 1060px) {
      min-width: 920px;
      max-width: 920px;
    }

    @media only screen and (max-width: 991px) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  &-card {
    background-color: $white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 1px 4px 20px #f3f3f3;
    width: 100%;
    margin-bottom: 50px;

    .rs-panel {
      border-bottom: 1px solid #0000001a;
      border-radius: 0;

      &-header {
        padding: 20px 0px;
      }

      &-body {
        padding: 0px 0px 10px;
      }
    }

    .table-responsive {
      &::-webkit-scrollbar {
        width: 6px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #009ef7;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .table {
      thead {
        tr {
          th {
            &:nth-last-child(1) {
              background-color: #f7f8ff;
              position: sticky;
              position: -webkit-sticky;
              right: 0;
              z-index: 1;
              background-image: linear-gradient(to right, #f8f8f8, white);
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-last-child(1) {
              background-color: #f7f8ff;
              position: sticky;
              position: -webkit-sticky;
              right: 0;
              z-index: 1;
              background-image: linear-gradient(to right, #f8f8f8, white);
            }
          }
        }
      }
    }
  }

  &-sidebar {
    padding: 100px 15px 15px;
    background-color: #fcfcfc;
    width: 250px;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 4;
    height: 100vh;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    // overflow-y: auto;

    @media only screen and (max-width: 550px) {
      right: -230px;
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #2d78d9;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &.off {
      // width: 0px;
      right: -235px;
      // padding: 10px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;

      @media only screen and (max-width: 550px) {
        right: 0px;
      }

      .Nurses-sidebar_toggler {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;

        @media only screen and (max-width: 550px) {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transition: 0.4s;
          -webkit-transition: 0.4s;
          -moz-transition: 0.4s;
          -ms-transition: 0.4s;
          -o-transition: 0.4s;
        }

        //&-i1 {
        //     opacity: 0;
        //     display: none;
        //     transition:  0.4s;
        //     -webkit-transition:  0.4s;
        //     -moz-transition:  0.4s;
        //     -ms-transition:  0.4s;
        //     -o-transition:  0.4s;
        // }

        // &-i2 {
        //     opacity: 1;
        //     display: block;
        //     transition:  0.4s;
        //     -webkit-transition:  0.4s;
        //     -moz-transition:  0.4s;
        //     -ms-transition:  0.4s;
        //     -o-transition:  0.4s;
        // }
      }
    }

    &_inner {
      opacity: 1;
      width: 100%;
      overflow: hidden;

      .rs-picker {
        margin: 10px 0px 10px;
        width: 100%;
        border: 0px;
      }
    }

    &_toggler {
      width: 35px;
      height: 35px;
      background-color: #fff;
      border-radius: 15px;
      // box-shadow: 0px 4px 20px 0px #009ef70f;
      // border: 3px solid #009ef71c;
      border: 4px solid #f5f8fa;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #009ef7;
      position: absolute;
      top: 125px;
      left: -20px;
      cursor: pointer;

      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;

      @media only screen and (max-width: 550px) {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
      }

      &-i1 {
        // transform: rotate(0deg);
        //opacity: 1;
        // display: block;
        // transition:  0.4s;
        // -webkit-transition:  0.4s;
        // -moz-transition:  0.4s;
        // -ms-transition:  0.4s;
        // -o-transition:  0.4s;
        // -webkit-transform: rotate(0deg);
        // -moz-transform: rotate(0deg);
        // -ms-transform: rotate(0deg);
        // -o-transform: rotate(0deg);
      }

      // &-i2 {
      //     opacity: 0;
      //     display: none;
      //     transition: 0.4s;
      //     -webkit-transition: 0.4s;
      //     -moz-transition: 0.4s;
      //     -ms-transition: 0.4s;
      //     -o-transition: 0.4s;
      // }
    }
  }

  &-mainBody {
    width: 100%;
    // min-height: 100vh;
    background-color: #0000;
    padding-right: 290px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;

    @media only screen and (max-width: 550px) {
      padding-right: 25px;
    }

    &.off {
      padding-right: 70px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;

      @media only screen and (max-width: 550px) {
        padding-right: 25px;
      }
    }
  }
}