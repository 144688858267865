.customModal {
  .modal {
    &-content {
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      box-shadow: 0px 4px 25px 0px #0004;
    }

    &-header {
      padding: 20px 25px 0px;
      border-bottom: 0px solid #fff;

      h4 {
        font-weight: 500 !important;
        font-size: 20px !important;
      }
    }

    &-body {
      padding: 20px 25px;

      .form {
        &-label {
          letter-spacing: 0.05em;
          margin-bottom: 3px;
          // color: #707070;
          color: #cfcccc;
        }

        &-control {
          height: 40px;
          border: 0px;
          border-bottom: 1px solid #d9d9d9;
          border-radius: 0px;
          padding: 10px 7px 10px 0px;
        }
      }

      textarea {
        &.form-control {
          height: 115px;
        }
      }

      .rs {
        &-picker {
          &-toggle {
            padding-left: 0px;
            padding-bottom: 0px;
            padding-top: 10px;
            height: 40px;
            display: flex;
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #d9d9d9;

            svg {
              top: 10px;
            }
          }
        }

        &-checkbox {
          &-checker {
            margin-left: -11px;
          }
        }
      }

      .btn {
        width: 100%;
        height: 45px;
        letter-spacing: 0.05em;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        &-primary {
          width: 110px;
        }
      }
    }
  }
}
