.custom-onboarding-banner {
  &-none {
    display: none !important;
  }

  &_container {
    // background-color: #d4ecee;
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
    border-radius: 15px;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    box-shadow: 1px 4px 20px #f3f3f3;

    @media only screen and (max-width: 991px) {
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &_mediaImg {
    width: 215px;
    height: 100%;
    margin-left: 20px;
    // margin-top: -20px;
    object-fit: contain;
  }

  &_content {
    padding: 20px;
    margin-left: 25px;
    width: 100%;
  }

  &_close {
    margin-left: auto;
    padding-right: 0px;
    padding-top: 0px;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.3);
  }
}
