.SchedulePage {
  background-color: #f5f8fa;
  position: relative;
  min-height: 100vh;

  &-container {
    min-width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media only screen and (max-width: 1400px) {
      min-width: 100%;
      max-width: 100%;
      padding: 10px;
    }

    @media only screen and (max-width: 1270px) {
      min-width: 100%;
      max-width: 100%;
      padding: 10px;
    }

    @media only screen and (max-width: 1060px) {
      min-width: 100%;
      max-width: 100%;
      padding: 10px;
    }

    @media only screen and (max-width: 991px) {
      min-width: 100%;
      max-width: 100%;
      padding: 10px;
    }
  }

  &-card {
    background-color: #fff;
    padding: 0;
    border-radius: 10px;
    box-shadow: 1px 4px 20px #f3f3f3;
    width: 100%;
    // margin-bottom: 50px;

    @media only screen and (max-width: 769px) {
      margin-bottom: 10px;
    }

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    &_body {
      position: relative;

      .main_calendar {
        min-height: 70vh;

        &-top {
          height: 42px;
          display: flex;
          justify-content: flex-start;

          &-left {
            width: 230px;
          }

          &-right {
            width: calc(100% - 230px);
            flex: 1;
            display: flex;
            overflow-x: auto;

            &::-webkit-scrollbar {
              width: 0px;
              height: 0px;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
              background: #009ef7;
              border-radius: 0px;
              -webkit-border-radius: 0px;
              -moz-border-radius: 0px;
              -ms-border-radius: 0px;
              -o-border-radius: 0px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }
        }

        &-bottom {
          position: relative;
          display: flex;
          justify-content: flex-start;

          &-left {
            &-inner {
              width: 230px;
              height: calc(75vh - 49px);
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              &::-webkit-scrollbar-thumb {
                background: #009ef7;
                border-radius: 0px;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                -ms-border-radius: 0px;
                -o-border-radius: 0px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            }
          }

          &-right {
            width: calc(100% - 230px);
            height: calc(75vh - 42px);
            // margin-left: 230px;
          }
        }
      }

      .Grid,
      .grid-container {
        &::-webkit-scrollbar {
          width: 6px;
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #009ef7;
          border-radius: 0px;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          -ms-border-radius: 0px;
          -o-border-radius: 0px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }

  &-header {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #3f4254;
    border-bottom: 1px solid #e4e6ef;
    padding-bottom: 6px;
    margin-bottom: 15px;
    padding-left: 10px;
  }

  &-sidebar {
    padding: 100px 15px 15px;
    background-color: #fcfcfc;
    width: 250px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
    height: 100vh;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    // overflow-y: auto;

    @media only screen and (max-width: 550px) {
      left: -230px;
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #2d78d9;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &.off {
      // width: 0px;
      left: -230px;
      // padding: 10px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;

      @media only screen and (max-width: 550px) {
        left: 0px;
      }

      .SchedulePage-sidebar_inner {
        // opacity: 0;
        // width: 0px;
        // overflow: hidden;
      }

      .SchedulePage-sidebar_toggler {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;

        @media only screen and (max-width: 550px) {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transition: 0.4s;
          -webkit-transition: 0.4s;
          -moz-transition: 0.4s;
          -ms-transition: 0.4s;
          -o-transition: 0.4s;
        }

        //&-i1 {
        //     opacity: 0;
        //     display: none;
        //     transition:  0.4s;
        //     -webkit-transition:  0.4s;
        //     -moz-transition:  0.4s;
        //     -ms-transition:  0.4s;
        //     -o-transition:  0.4s;
        // }

        // &-i2 {
        //     opacity: 1;
        //     display: block;
        //     transition:  0.4s;
        //     -webkit-transition:  0.4s;
        //     -moz-transition:  0.4s;
        //     -ms-transition:  0.4s;
        //     -o-transition:  0.4s;
        // }
      }
    }

    &_inner {
      opacity: 1;
      width: 100%;
      overflow: hidden;

      .rs-picker {
        margin: 10px 0px 10px;
        width: 100%;
        border: 0px;
      }
    }

    &_toggler {
      width: 35px;
      height: 35px;
      background-color: #fff;
      border-radius: 15px;
      // box-shadow: 0px 4px 20px 0px #009ef70f;
      // border: 3px solid #009ef71c;
      border: 4px solid #f5f8fa;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #009ef7;
      position: absolute;
      top: 125px;
      right: -20px;
      cursor: pointer;

      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;

      @media only screen and (max-width: 550px) {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
      }

      &-i1 {
        // transform: rotate(0deg);
        //opacity: 1;
        // display: block;
        // transition:  0.4s;
        // -webkit-transition:  0.4s;
        // -moz-transition:  0.4s;
        // -ms-transition:  0.4s;
        // -o-transition:  0.4s;
        // -webkit-transform: rotate(0deg);
        // -moz-transform: rotate(0deg);
        // -ms-transform: rotate(0deg);
        // -o-transform: rotate(0deg);
      }

      // &-i2 {
      //     opacity: 0;
      //     display: none;
      //     transition: 0.4s;
      //     -webkit-transition: 0.4s;
      //     -moz-transition: 0.4s;
      //     -ms-transition: 0.4s;
      //     -o-transition: 0.4s;
      // }
    }
  }

  &-mainBody {
    width: 100%;
    // min-height: 100vh;
    background-color: #0000;
    padding-left: 260px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;

    @media only screen and (max-width: 550px) {
      padding-left: 25px;
    }

    &.off {
      padding-left: 25px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;

      @media only screen and (max-width: 550px) {
        padding-left: 25px;
      }
    }
  }
  .calendar_move_btn {
    position: absolute;
    top: -37px;
    right: 10px;
  }
}
