// .noti-icon .badge {
//   left: 23px;
// }
// .mt-2 {
//   margin-top: 20px !important;
// }

// .dataTables_filter,
// .dataTables_paginate {
//   float: right;
// }

// .rdw-editor-main {
//   border: 1px solid #eeeef5;
//   height: 239px;
// }
// .dz-message {
//   text-align: center;
//   padding: 100px;
// }
// .fcYNFc {
//   background-color: #ffffff !important;
// }
// .task-box {
//   border: 1px solid #e1e1e6;
// }
// .react-datepicker-wrapper {
//   width: 100% !important;
// }

// .ReactModal__Overlay {
//   z-index: 1001 !important;
// }

// .fc-event .fc-content {
//   padding: 5px;
//   color: #fff;
// }
// .chat-conversation .right .conversation-list {
//   margin-right: 15px;
// }

// .external-event {
//   &:hover {
//     cursor: pointer;
//   }
// }

// .rangeslider-horizontal .rangeslider__fill {
//   background-color: #7cb342 !important;
// }

// .schedule-table {
//   thead{
//     tr{
//       th {
//         font-weight: $font-weight-bold;
//         background: #FFFFFF;
//         border-bottom:'1px solid #dddddd';
//         padding:'2px';
//         border-right:'1px solid #dddddd';
//     }
//     }
//   }

// }
// .schedule-grid{
//   width: 100% !important;
//   min-height: calc(100vh - 150px);
// }

.border-radius-10 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.auto-horizontal-scroll {
  max-width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.auto-horizontal-scroll::-webkit-scrollbar {
  height: 3px;
}

.auto-horizontal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.auto-horizontal-scroll::-webkit-scrollbar-thumb {
  background: #4475d155;
  border-radius: 10px;
}

.auto-horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#map_canvas {
  height: 500px;
  position: relative !important;
}

a.active button {
  border-bottom: 3px solid #4475d1;
}

.border-danger div {
  border: 1px solid #f46a6a !important;
}

.right-body {
  width: calc(100% - 250px) !important;
}

@media (max-width: 1200px) {
  .right-body {
    width: calc(100% - 100px) !important;
  }
}

.shift-status-tag {
  color: #fff;
  width: fit-content;
  padding: 8px;
  border-radius: 5px;
  font-size: 0.8125rem;
}

// 
.rs-anim-fade.rs-anim-in {
  z-index: 9999;
}

.footer {
  z-index: 100;
}

.cursor-pointer {
  cursor: pointer !important;
}

._loading_overlay_content {
  color: #009EF7;
}

.rbc-row-content {
  overflow-y: scroll;
}

.excerpt-text {
  display: block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flatpickr {
  .form-control[readonly] {
    background-color: #fff;
  }

  width: 90%;
}

.flatpickr-timer {
  .input-group-text {
    background-color: #fff;
    border-width: 0px;
    padding: 0em;
  }
}

.flatpickr-input-group {
  .input-group {
    align-items: baseline;
  }
}

.panel-collapsible {
  .rs-anim-collapse.rs-anim-in {
    background-color: #fff;
    padding-top: 10px;
  }
}

.help-breadcrumb {
  @media (max-width: 600px) {
    .rs-breadcrumb {
      margin-bottom: 70px;
    }

    margin-top: 30px;
    margin-left: 26px;
  }

  margin-top: 50px;
  margin-left: 77px;
}

.faq-breadcrumb-dropdown {
  .rs-dropdown-menu {
    margin-right: 25px;
  }
}