
.ProfilePage {
    background-color: #F5F8FA;

    &-container {
        min-width: 1366px;
        max-width: 1366px;
        margin: 0 auto;

        @media only screen and (max-width: 1400px) {
            min-width: 1224px;
            max-width: 1224px;
        }

        @media only screen and (max-width: 1270px) {
            min-width: 1000px;
            max-width: 1000px;
        }

        @media only screen and (max-width: 1060px) {
            min-width: 920px;
            max-width: 920px;
        }

        @media only screen and (max-width: 991px) {
            min-width: 100%;
            max-width: 100%;
        }
    }

    &-card {
        background-color: $white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 1px 4px 20px #F3F3F3;
        width: 100%;
        margin-bottom: 50px;

        @media only screen and (max-width: 769px) {
            margin-bottom: 10px;
        }

        &.card_sticky_top {
            position: sticky;
            top: 0px;
        }
    }

    &-header {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #3F4254;
        border-bottom: 1px solid #E4E6EF;
        padding-bottom: 6px;
        margin-bottom: 15px;
        padding-left: 10px;
    }

    &-menu {

        @media only screen and (max-width: 769px) {
            display: flex;
        }

        &_item {
            padding: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            background-color: #fff;
            cursor: pointer;

            @media only screen and (max-width: 769px) {
                // flex: 1;
            }

            &.active {
                border-left: 2px solid #009EF7;
                padding-left: 10px;

                @media only screen and (max-width: 769px) {
                    border-left: 2px solid #fff;
                    border-bottom: 2px solid #009EF7;
                }

                .profilePageSidebar-item_activeIcon {
                    display: block;
                }
            }

            p {
                width: 100%;
                letter-spacing: 0.05em;
                padding: 0px 10px;
            }

            &_activeIcon {
                display: none;
            }
        }
    }

    &-pic {

        &_wrapper {
            position: relative;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &_img {
            width: 350px;
            height: 350px;
            border-radius: 30px;
            margin: 0 auto;
            border: 10px solid #fff;
        }

        &_input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        &_button {
            width: 150px;
            height: 50px;
            background-color: #fff;
            color: #009EF7;
            border-radius: 5px;
            border: 2px solid #fff;
            box-shadow: 0px 0px 10px 0px #009EF750 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            position: absolute;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
}
    }

    &-form {
        width: 100%;
        // max-width: 70%;

        @media only screen and (max-width: 769px) {
          max-width: 100%;
        }

        h4 {
          width: 100%;
          font-size: 20px;
          color: #666;
          letter-spacing: 0.05em;
        }

        h1 {
          background: -webkit-linear-gradient(
            149deg,
            rgba(213, 231, 255, 1) 0%,
            rgba(225, 185, 255, 1) 53%,
            rgba(255, 230, 163, 1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 25px;
          letter-spacing: 0.05em;
        }

        .form-label {
          font-size: 12px;
          font-weight: bold;
          color: #444;
          letter-spacing: 0.08em;
        }

        .form-control {
          border-radius: 5px;
          min-height: 40px;
          //margin-bottom: 1rem        ;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
}

        .cus-btn {
          border-radius: 5px;
          height: 50px;
          margin-top: 10px;
          margin-bottom: 25px;
          font-weight: bold;
          letter-spacing: 0.1em;
          width: 100%;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
        }
      }

    // .profileModalImg {
    //     width: 100px;
    //     height: 100px;
    //     object-fit: cover;
    //     border-radius: 5px;
    // }

    // .avatar-title.profileModalImg {
    //     width: 100px;
    //     height: 100px;
    //     object-fit: cover;
    //     border-radius: 5px;
    //     font-size: 2rem !important;
    //     font-weight: 800;
    // }

    // .card-body {
    //     padding: 1.25rem 1.5rem;
    // }

    // .profileModal.table > :not(caption) > * > * {
    //     padding: 0.75rem 0rem;
    // }

    // .profileMoodalPara {
    //     padding: 0.5rem 0 0rem;
    // }

    // .rs-picker-date-menu {
    //     z-index: 9999 !important;
    // }

    // .profilePage {
    //     box-shadow: 0px 0px 100px 0px #0000 !important;
    //     max-width: 100%;
    //     margin: 0 auto;
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     flex-direction: column;
    //     // border-radius: 15px !important;
    //     padding: 25px !important;
    //     min-height: 81vh;
    //     border-left: 1px solid #0001 !important;
    // }

    // .profilePageImgWrapper {
    //     position: relative;
    //     margin-bottom: 50px;
    // }

    // .profilePageImgInput {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     opacity: 0;
    // }

    // .profilePageImgWrapperButton {
    //     width: 50px;
    //     height: 50px;
    //     background-color: #fff;
    //     border-radius: 50%;
    //     box-shadow: 0px 0px 10px 0px #0002 !important;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: 20px;
    //     position: absolute;
    //     bottom: -25px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     cursor: pointer;
    // }

    // .profilePageImg {
    //     width: 250px;
    //     height: 250px;
    //     border-radius: 50%;
    //     margin: 0 auto;
    //     // margin-bottom: 10px;
    //     // margin-top: -75px;
    //     border: 10px solid #fff;
    // }

    // .profilePage h3 {
    //     margin-bottom: 7px;
    // }

    // .profilePage p {
    //     margin-bottom: 15px;
    // }

    // .profileImgBtnWrapper {
    //     width: 100%;
    // }

    // .profileImgBtn {
    //     width: 200px !important;
    //     border-radius: 5px !important;
    //     padding: 10px !important;
    //     font-size: 14px !important;
    //     font-weight: bold !important;
    //     letter-spacing: 0.07em !important;
    // }

    // .profilePageSidebar {
    //     padding: 10px;
    // }

    // .profilePageSidebar-item {
    //     padding: 18px;
    //     border-bottom: 1px solid #0001;
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     font-size: 14px;
    //     background-color: #fff;
    //     cursor: pointer;
    // }

    // .profilePageSidebar-item.active {
    //     background-color: #fafafa;
    // }

    // .profilePageSidebar-item_activeIcon {
    //     display: none;
    // }

    // .profilePageSidebar-item.active .profilePageSidebar-item_activeIcon {
    //     display: block;
    // }

    // .profilePageSidebar-item-icon {
    //     margin-right: 10px;
    // }

    // .profilePageSidebar-item p {
    //     width: 100%;
    //     font-weight: bold;
    //     letter-spacing: 0.05em;
    // }

    // @media only screen and (max-width: 600px) {
    //     .profilePage {
    //         min-height: 100vh;
    //     }

    //     .profilePageSidebar {
    //         padding: 0px;
    //         display: flex;
    //         justify-content: flex-start;
    //         align-items: flex-start;
    //     }

    //     .profilePageSidebar-item.active .profilePageSidebar-item_activeIcon {
    //         display: none;
    //     }

    //     .profilePageImg {
    //         border: 10px solid #fafafa;
    //     }
    // }

}