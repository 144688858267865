.calendar-view{
       height: 100vh;
       width: 100%;
       overflow-y: auto;
       padding-bottom: 200px;
    .grid-container {
        display: grid;
        grid-template-columns: auto auto auto;
        // padding: 10px;
    }
    
    .grid-item {
        border: 1px solid #d9dddd;
        padding: 10px;
        text-align: center;
    }
    .grid-item-value {
        display: flex;
    align-items: center;
    justify-content: center;
    }
}
